@import "minima";

$navbar-color: #111111;
$background-color: #ffffff;
$text-color: #322d4f;
$highlight-color: #9340ff;
$background-code: #282a36;

html,
body {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: $text-color;
    background-color: $background-color;
}

section {
    width:600px;
    float:right;
    padding-bottom:50px;
  }

.wrapper {
max-width: 100%;
margin: 0;
padding: 0;
}

.wrapper-content, .post{
    max-width: 960px;
    margin-left:auto;
    margin-right:auto;
    padding-left: 30px;
    padding-right: 30px;
}

h1{
    font-weight: bold;
}

a {
    color: $highlight-color;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    color: $highlight-color;
    text-decoration: none;
}

a:visited {
    color: $highlight-color;
}

.site-header{
    background-color: $navbar-color;
    border: none;
}

.site-footer{
    padding-left: 20px;
    padding-right: 20px;
}

.site-header .wrapper{
    padding: 0 20px;
}

.site-title{
    font-weight: bold;
    color: #ffffff;
}


.site-title:visited{
    font-weight: bold;
    color: #ffffff;
}

.page-content{
    padding-top: 0px;
}

.hero-container{
    background-color: $background-code;
    min-height: 80vh;
    max-height: 980px;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ml-form-embedWrapper{
    background-color: inherit !important;
}

.hero{
    max-width: 960px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 0 15px;
}

.hero-title{
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
}

.hero-subscribe{
    max-width: 700px;
    width: 100%;
}

.subscribe-container{
    padding: 50px;
    background-color: $background-code;
    color: $background-color;
    border-radius: 15px;

}

.page-link{
    color: $highlight-color;
}

.page-link:active{
    color: $highlight-color;
}

.page-link:visited{
    color: $highlight-color;
}

.page-link:hover {
    color: $highlight-color;
    text-decoration: none;
}

.post, .post-list > li{
    background-color: $background-color;
}

.post-meta{
    margin-bottom: 10px;
}

.post-list > li{
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    display: flex;
}

.post-list-thumbnail img{
    min-width: 350px;
    border-radius: 15px;
}

.post-list-content{
    margin-left: 15px;
}

.post-image{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.site-nav .page-link{
    font-weight: bold;
    color: #ffffff;
}

.site-nav .page-link:hover{
    font-weight: bold;
    color:darken(#ffffff, 10%);
}

img{
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.profile-img {
    border-radius: 50%;
    border: 2px solid #322d4f;
}

.post-link:visited{
    color: inherit;
}

.post-link{
    color: inherit;
}

.post-link:hover{
    color: inherit;
}

.subscribe-box{
    border: 5px solid #D05F46;
    background-color: #1a2936;
    padding: 1rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.subscribe-box__button{
    background-color: $background-color;
    color: #ddac13;;
    border-color: #ddac13;;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1 px solid;
    cursor: pointer;
}

.subscribe-box input {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #D05F46;
}

.subscribe-box__button:hover{
    background-color: #BF9E74;
    cursor: pointer;
}

#response{
    font-size: small;
}

@media only screen and (max-width: 760px) {
    .post-list-thumbnail img{
        display:none;
    }

    .post-list-content{
        margin-left: 0px;
    }

    .hero-title{
        font-size: 2rem;
    }

    .site-nav .page-link{
        font-weight: bold;
        color: inherit;
    }
    
    .site-nav .page-link:hover{
        font-weight: bold;
        color:inherit;
    }
  }
